<template>
  <v-container>
    <v-row >
      <v-col>

        <!-- AVISO TEMPORAL  -->
        <v-snackbar
          v-model="snackbar"
          :timeout="8000"
          top
          :color="color"
          >
          {{text}}
          <v-btn
            color="white"
            text
            @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </v-snackbar>

        <!-- ENCABEZADOS -->
        <v-row >
          <v-col cols="12" sm="6">
            <v-text-field
              label="Nombre del Cliente"
              v-model ="Nomcli"
              validate-on-blur
              class="pa-0"
              filled shaped   hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              label="Nombre comercial"
              v-model ="Nomcomer"
              class="pa-0"
              shaped   outlined  auto-grow hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1" @click="e1 = 1">Datos Físcales</v-stepper-step>
            <v-stepper-step :complete="e1 > 2" step="2" @click="e1 = 2 ">Contacto</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Datos fiscales -->
            <v-stepper-content step="1">
              <v-card class="mb-1" color="" height="auto" >

                <v-row >
                  <v-col cols="12" sm="4">
                    <v-row>
                      <v-col col="12" sm="10">
                        <v-text-field
                        label="Calle"
                        v-model="Calle"
                        filled shaped
                        ></v-text-field>

                        <v-text-field
                        label="Número exterior"
                        v-model="Numext"
                        filled shaped
                        ></v-text-field>

                        <v-text-field
                        label="Número Interior"
                        v-model="Numint"
                        filled shaped
                        ></v-text-field>
                      </v-col>
                     </v-row>
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-row>
                        <v-col col="12" sm="10">
                          <v-text-field
                          label="Colonia"
                          v-model="Colonia"
                          filled shaped
                          ></v-text-field>

                          <v-text-field
                          label="Ciudad"
                          v-model="Ciudad"
                          filled shaped
                          ></v-text-field>

                          <v-text-field
                          label="Estado"
                          v-model="Estado"
                          filled shaped
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" sm="4">
                      <v-row>
                        <v-col col="12" sm="10">
                          <v-text-field
                          label="Pais"
                          v-model="Pais"
                          filled shaped
                          ></v-text-field>

                          <v-text-field
                          label="Cp"
                          v-model="Cp"
                          filled shaped
                          ></v-text-field>

                          <v-text-field
                          label="RFC"
                          v-model="Rfc"
                          filled shaped
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>

                  </v-row>
                </v-card>
                <v-btn class="ma-1" small color="primary" @click="e1 = 2" > Continuar </v-btn>
                <!-- <v-btn class="ma-1" small color="orange" dark @click="e1 = 3">Regresar</v-btn> -->
              </v-stepper-content>

            <!-- RESPONSABLE DE CONTRATO -->
            <v-stepper-content step="2">
              <v-card class="mb-5" color="" height="auto">
                <v-row >
                  <v-col cols="12" sm="6">
                    <v-row>
                      <v-col col="12" sm="10">
                        <v-text-field
                          label="Nombre "
                          filled shaped
                          v-model="Atvent"
                          color="#8CC642"
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-row>
                      <v-col col="12" sm="10">
                        <v-text-field
                          label="Email"
                          filled shaped
                          v-model="Email1"
                          color="#8CC642"
                        ></v-text-field>
                        <v-text-field
                          label="Telefono"
                          filled shaped
                          v-model="Telefono1"
                          color="#8CC642"
                          type="number"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                </v-row>
              </v-card>
              <v-btn class="ma-1" small color="orange" dark  @click="e1 = 1">Regresar</v-btn>
              <v-btn
                small
                dark
                class="ma-2"
                @click.prevent="agregar"
                color="blue darken-3">Grabar
              </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      e1: 0,
      text: '',
      snackbar: false,
      color: '',

      // Variables
      Nomcli: '',
      Nomcomer: '',

      Calle: '',
      Numext: '',
      Numint: '',
      Colonia: '',
      Ciudad: '',
      Estado: '',
      Pais: '',
      Cp: '',
      Rfc: '',

      Atvent: '',
      Puesto: '',
      Email1: '',
      Telefono1: '',

      Atpagos: '',
      Email2: '',
      Emailfac: '',
      Telefono2: '',

      Obs: ''

    }
  },

  computed: {
    ...mapGetters('Login', ['getdatosUsuario'])
  },

  methods: {

    agregar () {
      const payload = {
        Nomcli: this.Nomcli,
        Nomcomer: this.Nomcomer,
        Calle: this.Calle,
        Numext: this.Numext,
        Numint: this.Numint,
        Colonia: this.Colonia,
        Ciudad: this.Ciudad,
        Estado: this.Estado,
        Pais: this.Pais,
        Cp: this.Cp,
        Rfc: this.Rfc,

        Atvent: this.Atvent,

        Email1: this.Email1,
        Telefono: this.Telefono,
        Numcli: ''
      }
      var me = this

      this.$http.post('api/v1/lastnumcli').then(response => {
        payload.Numcli = response.body.Numcli
        this.getdatosUsuario.numcli = response.body.Numcli
        this.$http.post('auth/api/v1/clientes', payload).then(response => {
          setTimeout(function () { me.$router.push({ name: 'catclientes' }) }, 2000)
        }).catch(function (error) {
          console.log(error)
        })
      }).catch(function (error) {
        console.log(error)
      })
    }
  }
}
</script>
